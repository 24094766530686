// Package Imports
import { UserOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const UsersResource: ResourceItem = {
  name: resourceName.userDetails,
  identifier: ModuleIdentifierConst.USER,
  list: `${resourceName.userDetails}`,
  create: `${resourceName.userDetails}/create`,
  edit: `${resourceName.userDetails}/edit/:id`,
  show: `${resourceName.userDetails}/show/:id`,
  options: { label: 'User', icon: <UserOutlined /> },
};

export default UsersResource;
