// Project Imports
import DashboardResource from 'core/resources/Dashboard';
import OrganizationResource from 'core/resources/Organization';
import UsersResource from 'core/resources/Users';
import RolesResource from 'core/resources/Roles';
import IncentiveProgramResource from 'core/resources/IncentiveProgram';
import AttributeResource from 'core/resources/Attribute';

const resources = [
  DashboardResource,
  OrganizationResource,
  IncentiveProgramResource,
  RolesResource,
  UsersResource,
  AttributeResource,
];

export default resources;
