// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';
// Lazy-loaded Component
const IncentiveProgramCreate = lazy(
  () => import('pages/incentiveProgram/incentiveProgramCreate')
);
const IncentiveProgramList = lazy(
  () => import('pages/incentiveProgram/incentiveProgramList')
);
const IncentiveProgramEdit = lazy(
  () => import('pages/incentiveProgram/incentiveProgramEdit')
);
const IncentiveProgramShow = lazy(
  () => import('pages/incentiveProgram/incentiveProgramShow')
);

const IncentiveProgramRoute = (
  <Route path={routeName.incentiveProgram}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INCENTIVE_VIEW,
            PrivateRouteConst.SELF_INCENTIVE_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <IncentiveProgramList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_INCENTIVE}>
          <Suspense fallback={<Loading />}>
            <IncentiveProgramCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INCENTIVE_EDIT,
            PrivateRouteConst.SELF_INCENTIVE_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <IncentiveProgramEdit />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INCENTIVE_VIEW,
            PrivateRouteConst.SELF_INCENTIVE_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <IncentiveProgramShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default IncentiveProgramRoute;
