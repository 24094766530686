const dateFormat = 'DD/MM/YYYY';
const ddmmmmyyyy = 'DD MMMM YYYY';
const success = 'success';
const error = 'error';
const info = 'info';
const warning = 'warning';

const constants = {
  dateFormat,
  ddmmmmyyyy,
  success,
  error,
  info,
  warning,
};

export default constants;
