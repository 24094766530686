// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';
// Lazy-loaded Components
const UsersCreate = lazy(() => import('pages/users/usersCreate'));
const UserEdit = lazy(() => import('pages/users/usersEdit'));
const UsersList = lazy(() => import('pages/users/usersList'));
const UsersShow = lazy(() => import('pages/users/usersShow'));

const UsersRoute = (
  <Route path={routeName.userDetails}>
    <Route
      index
      element={
        <PrivateRoute
          name={[PrivateRouteConst.USER_VIEW, PrivateRouteConst.SELF_USER_VIEW]}
        >
          <Suspense fallback={<Loading />}>
            <UsersList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_USER}>
          <Suspense fallback={<Loading />}>
            <UsersCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[PrivateRouteConst.USER_EDIT, PrivateRouteConst.SELF_USER_EDIT]}
        >
          <Suspense fallback={<Loading />}>
            <UserEdit />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[PrivateRouteConst.USER_VIEW, PrivateRouteConst.SELF_USER_VIEW]}
        >
          <Suspense fallback={<Loading />}>
            <UsersShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default UsersRoute;
