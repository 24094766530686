// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

// Project Imports
import routeName from 'core/common/routeName';
// import AttributeCreate from 'pages/attribute/AttributeCreate';
// import AttributeList from 'pages/attribute/AttributeList';
// import AttributeEdit from 'pages/attribute/AttributeEdit';
// import AttributeShow from 'pages/attribute/AttributeShow';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';

// Lazy-loaded Components
const AttributeList = lazy(() => import('pages/attribute/AttributeList'));
const AttributeCreate = lazy(() => import('pages/attribute/AttributeCreate'));
const AttributeEdit = lazy(() => import('pages/attribute/AttributeEdit'));
const AttributeShow = lazy(() => import('pages/attribute/AttributeShow'));

const AttributeRoute = (
  <Route path={routeName.attribute}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.ATTRIBUTE_VIEW,
            PrivateRouteConst.SELF_ATTRIBUTE_VIEW,
          ]}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AttributeList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_ATTRIBUTE}>
          <Suspense fallback={<div>Loading...</div>}>
            <AttributeCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.ATTRIBUTE_EDIT,
            PrivateRouteConst.SELF_ATTRIBUTE_EDIT,
          ]}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AttributeEdit />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.ATTRIBUTE_VIEW,
            PrivateRouteConst.SELF_ATTRIBUTE_VIEW,
          ]}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <AttributeShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default AttributeRoute;
