// Package Imports
import { MoneyCollectOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const IncentiveProgramResource: ResourceItem = {
  name: resourceName.incentiveProgram,
  identifier: ModuleIdentifierConst.INCENTIVE,
  list: `${resourceName.incentiveProgram}`,
  show: `${resourceName.incentiveProgram}/show/:id`,
  create: `${resourceName.incentiveProgram}/create`,
  edit: `${resourceName.incentiveProgram}/edit/:id`,
  options: { label: 'Incentive Program', icon: <MoneyCollectOutlined /> },
};

export default IncentiveProgramResource;
