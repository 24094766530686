// Package Imports
import { GroupOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const OrganizationResource: ResourceItem = {
  name: resourceName.organization,
  identifier: ModuleIdentifierConst.ORGANISATION,
  list: `${resourceName.organization}`,
  show: `${resourceName.organization}/show/:id`,
  create: `${resourceName.organization}/create`,
  edit: `${resourceName.organization}/edit/:id`,
  options: { label: 'Organization', icon: <GroupOutlined /> },

  meta: {
    dataProviderName: 'organizationDataProvider',
  },
};

export default OrganizationResource;
