// Package Imports
import { SettingOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const RolesResource: ResourceItem = {
  name: resourceName.roles,
  identifier: ModuleIdentifierConst.ROLE,
  list: `${resourceName.roles}`,
  show: `${resourceName.roles}/show/:id`,
  create: `${resourceName.roles}/create`,
  edit: `${resourceName.roles}/edit/:id`,
  options: { label: 'Role', icon: <SettingOutlined /> },
  meta: {
    dataProviderName: 'rolePermissionDataProvider',
  },
};

export default RolesResource;
