// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';
// Lazy-loaded Components
const RolesCreate = lazy(() => import('pages/roles/rolesCreate'));
const RolesEdit = lazy(() => import('pages/roles/rolesEdit'));
const RolesShow = lazy(() => import('pages/roles/rolesShow'));
const RolesList = lazy(() => import('pages/roles/rolesList'));

const RolesRoute = (
  <Route path={routeName.roles}>
    <Route
      index
      element={
        <PrivateRoute
          name={[PrivateRouteConst.ROLE_VIEW, PrivateRouteConst.SELF_ROLE_VIEW]}
        >
          <Suspense fallback={<Loading />}>
            <RolesList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_ROLE}>
          <Suspense fallback={<Loading />}>
            <RolesCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[PrivateRouteConst.ROLE_EDIT, PrivateRouteConst.SELF_ROLE_EDIT]}
        >
          <Suspense fallback={<Loading />}>
            <RolesEdit />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[PrivateRouteConst.ROLE_VIEW, PrivateRouteConst.SELF_ROLE_VIEW]}
        >
          <Suspense fallback={<Loading />}>
            <RolesShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default RolesRoute;
