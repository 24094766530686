const ModuleIdentifierConst = {
  ORGANISATION: 'organizationManagement',
  ATTRIBUTE: 'userAttributeManagement',
  ROLE: 'roleManagement',
  USER: 'user',
  PERMISSION: 'permissionsManagement',
  INCENTIVE: 'incentiveManagement',
};

export default ModuleIdentifierConst;
