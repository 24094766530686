const dashboard = 'dashboard';
const login = 'login';
const error = 'error';
const register = 'register';
const userDetails = 'user_details';
const organization = 'organization';
const roles = 'roles';
const permissions = 'permissions';
const incentiveProgram = 'incentive_program';
const attribute = 'user_attr_master_mapping';
const profile = 'profile';

const resourceName = {
  dashboard,
  login,
  error,
  register,
  userDetails,
  organization,
  roles,
  incentiveProgram,
  attribute,
  permissions,
  profile,
};

export default resourceName;
