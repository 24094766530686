const PrivateRouteConst = {
  USER_VIEW: 'allUserView',
  USER_EDIT: 'allUserEdit',
  USER_DELETE: 'allUserDelete',
  SELF_USER_VIEW: 'selfUserView',
  SELF_USER_EDIT: 'selfUserEdit',
  SELF_USER_DELETE: 'selfUserDelete',
  CREATE_USER: 'createUser',
  ORGANISATION_VIEW: 'allOrganizationView',
  ORGANISATION_EDIT: 'allOrganizationEdit',
  ORGANISATION_DELETE: 'allOrganizationDelete',
  SELF_ORGANISATION_VIEW: 'selfOrganizationView',
  SELF_ORGANISATION_EDIT: 'selfOrganizationEdit',
  SELF_ORGANISATION_DELETE: 'selfOrganizationDelete',
  CREATE_ORGANISATION: 'createOrganization',
  ROLE_VIEW: 'allRoleView',
  ROLE_EDIT: 'allRoleEdit',
  ROLE_DELETE: 'allRoleDelete',
  SELF_ROLE_VIEW: 'selfRoleView',
  SELF_ROLE_EDIT: 'selfRoleEdit',
  SELF_ROLE_DELETE: 'selfRoleDelete',
  CREATE_ROLE: 'createRole',
  INCENTIVE_VIEW: 'allIncentiveView',
  INCENTIVE_EDIT: 'allIncentiveEdit',
  INCENTIVE_DELETE: 'allIncentiveDelete',
  SELF_INCENTIVE_VIEW: 'selfIncentiveView',
  SELF_INCENTIVE_EDIT: 'selfIncentiveEdit',
  SELF_INCENTIVE_DELETE: 'selfIncentiveDelete',
  CREATE_INCENTIVE: 'createIncentive',
  PERMISSION_VIEW: 'allManagePermissionView',
  PERMISSION_EDIT: 'allManagePermissionEdit',
  PERMISSION_DELETE: 'allManagePermissionDelete',
  SELF_PERMISSION_VIEW: 'selfManagePermissionView',
  SELF_PERMISSION_EDIT: 'selfManagePermissionEdit',
  SELF_PERMISSION_DELETE: 'selfManagePermissionDelete',
  CREATE_PERMISSION: 'createManagePermission',
  ATTRIBUTE_VIEW: 'allAttributeView',
  ATTRIBUTE_EDIT: 'allAttributeEdit',
  ATTRIBUTE_DELETE: 'allAttributeDelete',
  SELF_ATTRIBUTE_VIEW: 'selfAttributeView',
  SELF_ATTRIBUTE_EDIT: 'selfAttributeEdit',
  SELF_ATTRIBUTE_DELETE: 'selfAttributeDelete',
  CREATE_ATTRIBUTE: 'createAttribute',
};

export default PrivateRouteConst;
