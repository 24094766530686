// Package Imports
import { TagOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const AttributeResource: ResourceItem = {
  name: resourceName.attribute,
  identifier: ModuleIdentifierConst.ATTRIBUTE,
  list: `${resourceName.attribute}`,
  show: `${resourceName.attribute}/show/:id`,
  create: `${resourceName.attribute}/create`,
  edit: `${resourceName.attribute}/edit/:id`,
  options: { label: 'Attribute', icon: <TagOutlined /> },
};

export default AttributeResource;
