// Package Imports
import { Button, Typography, Space } from 'antd';
import {
  useGo,
  useNavigation,
  useRouterType,
  useTranslate,
} from '@refinedev/core';

// Project Imports
import { Images } from 'core/common/imagePath';

const { Text } = Typography;
const AuthError = ({ message }) => {
  const { push } = useNavigation();
  const go = useGo();
  const routerType = useRouterType();
  const t = useTranslate();
  return (
    <div className="auth-error-container">
      <img height="200px" src={Images.AugmentLogoSmall} />
      <Space className="my-4">
        <Text className="text-lg">{message}</Text>
      </Space>
      <div>
        <Button
          type="primary"
          onClick={() => {
            if (routerType === 'legacy') {
              push('/');
            } else {
              go({ to: '/' });
            }
          }}
        >
          {t('error.backHome')}
        </Button>
      </div>
    </div>
  );
};

export default AuthError;
