// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
// import ManagePermission from 'pages/roles/ManagePermission';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';
// Lazy-loaded Components
const ManagePermission = lazy(() => import('pages/roles/ManagePermission'));

const PermissionRoute = (
  <Route path={routeName.roles}>
    <Route
      path={`create/:id/${routeName.permissions}`}
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_PERMISSION}>
          <Suspense fallback={<Loading />}>
            <ManagePermission type="create" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`edit/:id/${routeName.permissions}/`}
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.PERMISSION_EDIT,
            PrivateRouteConst.SELF_PERMISSION_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <ManagePermission type="edit" />
          </Suspense>
        </PrivateRoute>
      }
    />
    {/* <Route path={routeName.permissions}>
      <Route index element={<ManagePermission />} />
      <Route path="edit/:id" element={<ManagePermission />} />
      <Route path="show/:id" element={<ManagePermission />} />

    </Route> */}
  </Route>
);

export default PermissionRoute;
