// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';
// Lazy-loaded Component
const OrganizationCreate = lazy(
  () => import('pages/organization/organizationCreate')
);
const OrganizationEdit = lazy(
  () => import('pages/organization/organizationEdit')
);
const OrganizationList = lazy(
  () => import('pages/organization/organizationList')
);
const OrganizationShow = lazy(
  () => import('pages/organization/organizationShow')
);

const OrganizationRoute = (
  <Route path={routeName.organization}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.ORGANISATION_VIEW,
            PrivateRouteConst.SELF_ORGANISATION_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <OrganizationList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_ORGANISATION}>
          <Suspense fallback={<Loading />}>
            <OrganizationCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.ORGANISATION_EDIT,
            PrivateRouteConst.SELF_ORGANISATION_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <OrganizationEdit />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.ORGANISATION_VIEW,
            PrivateRouteConst.SELF_ORGANISATION_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <OrganizationShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default OrganizationRoute;
